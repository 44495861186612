import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import {
    Checkbox,
    CommandBarButton,
    FontWeights,
    IButtonStyles,
    Icon,
    IconButton,
    IOverflowSetItemProps,
    Label,
    OverflowSet,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { useSectionContext } from '../context';
import { ControlType } from '../../../../../enums/ControlType';
import { useIntl } from 'react-intl';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { useUpdateSectionAnswer } from '../../../hooks/answers';
import { useJobContext } from '../../../JobPortalLayoutPage';
import { PortalRole, useWorkContext } from '../../../../../providers';
import { useTabContext } from '../../../JobPortalPage';

enum SectionCommandType {
    Button = 0,
    CheckBox = 1,
}

type SectionAnswer = {
    checked: boolean;
    disabled: boolean;
};

type SectionState = {
    managerApproval: SectionAnswer;
    partnerApproval: SectionAnswer;
    sendEmail: SectionAnswer;
    isAdministrationSection: boolean;
    isSectionOpenedOnAdd: boolean;
};

type SectionTitleProps = {
    disabled?: boolean;
    disableApprovals?: boolean;
    childRef: MutableRefObject<any>;
};
export const SectionTitle = ({ disabled = false, disableApprovals, childRef }: SectionTitleProps) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();

    const {
        section,
        refresh,
        fullScreen,
        isOpened,
        open,
        close,
        isPositiveSectionAnswer,
        refreshSectionAnswers,
        isWarningMessageEnabled,
        isWarningMessageForced,
        isLoaded,
    } = useSectionContext();

    const classNames = mergeStyleSets({
        itemContent: {
            height: 50,
            paddingLeft: 10,
            overflow: 'hidden',
            flexGrow: 1,
            cursor: section.showExpander && !disabled ? 'pointer' : 'inherit',
            borderBottom: !disabled && isOpened ? `1px solid ${theme.palette.neutralTertiaryAlt}` : '',
        },
        itemHeader: [
            {
                padding: theme.spacing.m,
            },
        ],
        itemName: [
            theme.fonts.medium,
            {
                fontWeight: FontWeights.regular,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                span: { color: theme.schemes?.default?.semanticColors.bodyText },
            },
        ],
        chevron: {
            alignSelf: 'center',
            fontWeight: FontWeights.bold,
            color: theme.palette.themePrimary,
            fontsize: theme.fonts.tiny.fontsize,
            flexShrink: 0,
        },
        caption: {},
        captionPascal: {
            textTransform: 'lowercase',
            '::first-letter': {
                textTransform: 'uppercase',
            },
        },
    });

    const { isTabEnabled } = useTabContext();
    const { jobId } = useJobContext();
    const { workContext, isInRoleOrSuperAdministrator } = useWorkContext();

    const expanderIcon = disabled ? undefined : isOpened ? 'ChevronUp' : 'ChevronDown';
    const { updateAsync, isUpdating } = useUpdateSectionAnswer();

    const [sectionState, setSectionState] = useState<SectionState>({
        isAdministrationSection: section.reference === 'Administration',
        managerApproval: { checked: !!section.sectionAnswer?.managerApproval, disabled: true },
        partnerApproval: { checked: !!section.sectionAnswer?.partnerApproval, disabled: true },
        sendEmail: { checked: !!section.sectionAnswer?.sendEmail, disabled: true },
        isSectionOpenedOnAdd: true,
    });

    const handleRefresh = useCallback(() => {
        if (!disabled) refresh();
    }, [disabled, refresh]);

    const handleAdd = useCallback(() => {
        if (!childRef?.current?.onAddClick && !disabled) {
            open();
            setSectionState((prev) => ({ ...prev, isSectionOpenedOnAdd: false }));
        }

        if (!disabled) {
            childRef?.current?.onAddClick();
        }
    }, [childRef, disabled, open]);

    const handleFullScreen = useCallback(() => {
        if (!disabled) fullScreen();
    }, [disabled, fullScreen]);
    const handleOnClickTitle = () => {
        if (disabled) return;

        if (isOpened) close();
        else open();
    };

    const updateSectionAnswer = useCallback(
        async (approved: boolean, type: 'manager' | 'partner' | 'sendEmail') => {
            const values = {
                manager: type === 'manager' ? approved : sectionState.managerApproval.checked,
                partner: type === 'partner' ? approved : sectionState.partnerApproval.checked,
                sendEmail: type === 'sendEmail' ? approved : sectionState.sendEmail.checked,
            };

            if (sectionState.isAdministrationSection && type === 'sendEmail') {
                sessionStorage.setItem(`administration_${jobId}_${section.id}_${type}`, approved.toString());
            }

            await updateAsync({
                jobId: jobId,
                sectionId: section.id,
                managerApproval: values.manager,
                sendEmail: values.sendEmail,
                partnerApproval: values.partner,
            });

            if (type === 'manager')
                setSectionState((prev) => ({
                    ...prev,
                    managerApproval: { disabled: prev.managerApproval.disabled, checked: approved },
                }));
            if (type === 'partner')
                setSectionState((prev) => ({
                    ...prev,
                    partnerApproval: { disabled: prev.partnerApproval.disabled, checked: approved },
                }));
            if (type === 'sendEmail')
                setSectionState((prev) => ({
                    ...prev,
                    sendEmail: { disabled: prev.sendEmail.disabled, checked: approved },
                }));

            refreshSectionAnswers?.({ ...values } as any);
        },
        [jobId, refreshSectionAnswers, section.id, sectionState, updateAsync]
    );

    const onApprove = useCallback(
        async (approved: boolean, type: 'manager' | 'partner' | 'sendEmail') => {
            if (childRef?.current?.onApprove) {
                childRef.current.onApprove(approved, type, updateSectionAnswer);
                return;
            }

            await updateSectionAnswer(approved, type);
        },
        [childRef, updateSectionAnswer]
    );

    const [isManagerControlEnabled, setIsManagerControlEnabled] = useState<boolean>(false);
    useEffect(() => {
        setIsManagerControlEnabled(!disableApprovals && (!!workContext?.isCurrentUserJobManager || !!workContext?.isCurrentUserJobPartner || isInRoleOrSuperAdministrator(PortalRole.Administrator, PortalRole.ReviewerUser)) && !isUpdating && isTabEnabled);
    }, [workContext?.isCurrentUserJobManager, workContext?.isCurrentUserJobPartner, isUpdating, disableApprovals, isTabEnabled]);

    const [isPartnerControlEnabled, setIsPartnerControlEnabled] = useState<boolean>(false);
    useEffect(() => {
        setIsPartnerControlEnabled(!disableApprovals && (!!workContext?.isCurrentUserJobPartner || isInRoleOrSuperAdministrator(PortalRole.Administrator)) && !isUpdating && isTabEnabled);
    }, [workContext?.isCurrentUserJobPartner, isUpdating, disableApprovals, isTabEnabled]);

    const sectionCommands = useMemo<IOverflowSetItemProps[]>(
        () =>
            disabled
                ? []
                : [
                      {
                          key: 'add',
                          text: formatMessage({ id: 'add' }),
                          hidden: !section.showAddButtonInHeader,
                          iconOnly: true,
                          iconProps: { iconName: 'add' },
                          disabled: isUpdating,
                          onClick: handleAdd,
                          ariaLabel: formatMessage({ id: 'add' }),
                      },
                      {
                          key: 'managerApprove',
                          type: SectionCommandType.CheckBox,
                          checked: sectionState.managerApproval.checked,
                          text: formatMessage({ id: 'managerApprove' }),
                          hidden: !section.managerApprovalCheckBox,
                          disabled: !isManagerControlEnabled,
                          onChange: async (e: Event, value: boolean) => {
                              await onApprove(value, 'manager');
                          },
                          ariaLabel: formatMessage({ id: 'managerApprove' }),
                      },
                      {
                          key: 'partnerApprove',
                          type: SectionCommandType.CheckBox,
                          checked: sectionState.partnerApproval.checked,
                          text: formatMessage({ id: 'partnerApprove' }),
                          hidden: !section.partnerApprovalCheckBox,
                          disabled: !isPartnerControlEnabled,
                          onChange: async (e: Event, value: boolean) => {
                              await onApprove(value, 'partner');
                          },
                          ariaLabel: formatMessage({ id: 'partnerApprove' }),
                      },
                      {
                          key: 'sendEmail',
                          type: SectionCommandType.CheckBox,
                          checked: sectionState.sendEmail.checked,
                          text: formatMessage({ id: 'sendEmail' }),
                          hidden: !section.sendEmailCheckBox,
                          disabled: isUpdating || !isTabEnabled,
                          onChange: async (e: Event, value: boolean) => {
                              await onApprove(value, 'sendEmail');
                          },
                          ariaLabel: formatMessage({ id: 'sendEmail' }),
                      },
                      {
                          key: 'fullScreen',
                          text: formatMessage({ id: 'fullScreen' }),
                          ariaLabel: formatMessage({ id: 'fullScreen' }),
                          iconOnly: true,
                          hidden: !section.showFullScreen,
                          iconProps: { iconName: 'FullScreen' },
                          disabled: isUpdating,
                          onClick: handleFullScreen,
                          buttonStyles: {
                              iconHovered: {
                                  transition: 'transform .5s',
                                  transform: 'scale(1.1)',
                              },
                          },
                      },
                      {
                          key: 'refresh',
                          text: formatMessage({ id: 'refresh' }),
                          ariaLabel: formatMessage({ id: 'refresh' }),
                          iconOnly: true,
                          hidden: section.showRefresh ? !isOpened : false,
                          iconProps: { iconName: 'refresh' },
                          disabled: isUpdating,
                          buttonStyles: {
                              iconHovered: {
                                  transition: 'transform .7s',
                                  transform: 'rotate(360deg)',
                              },
                          },
                          onClick: handleRefresh,
                      },
                  ],
        [
            isOpened,
            section,
            sectionState,
            formatMessage,
            isUpdating,
            handleAdd,
            handleRefresh,
            handleFullScreen,
            onApprove,
            disableApprovals,
            disabled,
            isTabEnabled,
        ]
    );

    const showWarningMessage = useMemo(() => {
        const excludedSections = ['Query Summary'];
        return (
            !excludedSections.includes(section.reference ?? '') &&
            isWarningMessageEnabled &&
            (isWarningMessageForced || sectionState.partnerApproval.checked || sectionState.managerApproval.checked)
        );
    }, [sectionState, section, isWarningMessageEnabled, isWarningMessageForced]);

    const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
        if (item.hidden) {
            return <></>;
        }

        if (item.onRender) {
            return item.onRender(item);
        }

        switch (item.type) {
            case SectionCommandType.CheckBox:
                return (
                    <Checkbox
                        theme={item.checked ? theme : theme.schemes?.default}
                        checked={item.checked}
                        disabled={item.disabled}
                        onChange={item.onChange}
                        styles={{ root: { padding: 6 } }}
                        boxSide={'end'}
                        label={item.text}
                        key={item.key}
                    />
                );
            default:
                return (
                    <IconButton
                        hidden={item.hidden}
                        onClick={item.onClick}
                        ariaLabel={item.ariaLabel}
                        iconProps={item.iconProps}
                        styles={item.buttonStyles}
                        menuProps={item.subMenuProps}
                        text={item.text}
                    />
                );
        }
    };
    const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
        const buttonStyles: Partial<IButtonStyles> = {
            root: {
                minWidth: 0,
                padding: '0 4px',
                alignSelf: 'stretch',
                height: 'auto',
            },
        };
        return (
            <CommandBarButton
                ariaLabel='More items'
                styles={buttonStyles}
                menuIconProps={{ iconName: 'More' }}
                menuProps={{ items: overflowItems! }}
            />
        );
    };

    useEffect(() => {
        const partnerApprovalEnabled = !!workContext?.isCurrentUserJobPartner || isInRoleOrSuperAdministrator(PortalRole.Administrator);
        const managerApprovalEnabled =
            partnerApprovalEnabled &&
            (workContext?.isCurrentUserJobManager || isInRoleOrSuperAdministrator(PortalRole.Administrator, PortalRole.ReviewerUser));
        const resetSectionOpenOnAdd = isLoaded && childRef?.current?.onAddClick && !disabled;

        if (resetSectionOpenOnAdd && !sectionState.isSectionOpenedOnAdd) {
            childRef.current.onAddClick();
        }

        setSectionState((prev) => ({
            ...prev,
            managerApproval: { ...prev.managerApproval, disabled: !managerApprovalEnabled },
            partnerApproval: { ...prev.partnerApproval, disabled: !partnerApprovalEnabled },
            isSectionOpenedOnAdd: !resetSectionOpenOnAdd ,
        }));
    }, [workContext, isLoaded, childRef, disabled, isInRoleOrSuperAdministrator]);

    useEffect(() => {
        if (sectionState.isAdministrationSection && !sessionStorage.getItem(`administration_${jobId}_${section.id}_sendEmail`)) {
            onApprove(true, 'sendEmail');
        }
    }, [sectionState.isAdministrationSection, onApprove, jobId, section.id]);

    if (!section.showHeader) {
        return <></>;
    }

    return (
        <Stack
            tokens={{ childrenGap: 16 }}
            horizontal
            className={classNames.itemContent}
            horizontalAlign={'space-between'}
            verticalAlign={'center'}>
            <Stack
                verticalAlign={'center'}
                className={classNames.itemHeader}
                grow={3}
                horizontal
                tokens={{ childrenGap: 16 }}
                onClick={handleOnClickTitle}>
                {section.showExpander && <Icon iconName={expanderIcon} className={classNames.chevron} />}
                <Stack verticalAlign={'center'} horizontal tokens={{ childrenGap: 8 }} className={classNames.itemName}>
                    {section.controlType === ControlType.ReportStatement && (
                        <Icon iconName={'TextDocument'} style={{ color: theme.palette.themeDark }} />
                    )}
                    {section.showHeader ? (
                        <Text
                            style={{ color: disabled ? theme.semanticColors.disabledText : theme.schemes?.default?.semanticColors.bodyText }}
                            className={((text) => (text && !text.includes(' ') ? classNames.captionPascal : classNames.caption))(
                                section.caption
                            )}>
                            {section.caption}
                        </Text>
                    ) : (
                        ''
                    )}
                </Stack>
                {isPositiveSectionAnswer != null && !disabled && (
                    <Icon
                        iconName={isPositiveSectionAnswer ? 'CompletedSolid' : 'AlertSolid'}
                        styles={{ root: { color: isPositiveSectionAnswer ? theme.palette.green : theme.palette.red } }}
                    />
                )}
            </Stack>
            <Stack horizontal className={classNames.itemHeader}>
                {showWarningMessage && (
                    <Stack.Item>
                        <Label styles={{ root: { color: theme.palette.red, fontWeight: FontWeights.bold } }}>
                            {formatMessage({ id: 'approvalWarningMessage' })}
                        </Label>
                    </Stack.Item>
                )}
                <OverflowSet items={sectionCommands} onRenderOverflowButton={onRenderOverflowButton} onRenderItem={onRenderItem} />
            </Stack>
        </Stack>
    );
};
